import axios from 'axios';
import React, { useEffect, useState } from 'react';

import VehicleMake from './VehicleMake';
import VehicleModel from './VehicleModel';
import VehicleModelYear from './VehicleModelYear';
import VehicleOption from './VehicleOption';
import VehicleSize from './VehicleSize';

export default function VehicleRetail({ localizedData, loading, setLoading, submitting, setsubmitting, onSelectSize, showSubmitButton = true }) {
  const [yearValue, setyearValue] = useState('');
  const [makeValue, setMakeValue] = useState('');
  const [modelValue, setModelValue] = useState('');
  const [optionValue, setOptionValue] = useState('');
  const [sizeValue, setSizeValue] = useState('');
  const [Chassis, setChassis] = useState('');
  const [Model, setModel] = useState('');

  const handleSubmitForm = async () => {
    setsubmitting(true);
    const { data: termOptions } = await axios.get(`/wp-json/stm/v1/lookup/vehicle/term-options?tireSize=${sizeValue}`);
    const location = `${termOptions}?model=${yearValue} ${makeValue} ${modelValue}&type=Retail&tire_size=${sizeValue}`;
    window.location.href = location;
  };

  const handleSetSize = (size: string) => {
    setSizeValue(size);
    if (onSelectSize && typeof onSelectSize === 'function') {
      onSelectSize(size);
    }
  };

  useEffect(() => {
    setMakeValue('');
    setModelValue('');
    setOptionValue('');
    setSizeValue('');
  }, [yearValue]);

  useEffect(() => {
    setModelValue('');
    setOptionValue('');
    setSizeValue('');
  }, [makeValue]);

  useEffect(() => {
    setOptionValue('');
    setSizeValue('');
  }, [modelValue]);

  useEffect(() => {
    setSizeValue('');
  }, [optionValue]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="vehicle-retail">
        <div className="flex w-full flex-wrap">
          <VehicleModelYear localizedData={localizedData} yearValue={yearValue} setyearValue={setyearValue} />
          {yearValue ? (
            <VehicleMake localizedData={localizedData} yearValue={yearValue} makeValue={makeValue} setMakeValue={setMakeValue} />
          ) : (
            <div className="w-full md:w-6/12 ">
              <label htmlFor="vehicleRetailBrand" className="sr-only">
                {localizedData.optionsFields.make}
              </label>
              <select
                className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                name="brand"
                id="vehicleRetailBrand"
                value={makeValue}
                disabled={true}
                aria-label={localizedData.optionsFields.make}
                onChange={(e) => setMakeValue(e.target.value)}
              >
                <option value="">{localizedData.optionsFields.make}</option>
              </select>
            </div>
          )}
        </div>
        <div className="flex w-full flex-wrap">
          {makeValue ? (
            <VehicleModel localizedData={localizedData} yearValue={yearValue} makeValue={makeValue} modelValue={modelValue} setModelValue={setModelValue} />
          ) : (
            <div className="w-full md:w-6/12 md:pr-4 ">
              <select
                className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                name="brand"
                value={modelValue}
                disabled={true}
                aria-label={localizedData.optionsFields.model}
                onChange={(e) => setModelValue(e.target.value)}
              >
                <option value="">{localizedData.optionsFields.model}</option>
              </select>
            </div>
          )}
          {modelValue ? (
            <VehicleOption
              localizedData={localizedData}
              yearValue={yearValue}
              makeValue={makeValue}
              modelValue={modelValue}
              optionValue={optionValue}
              setOptionValue={setOptionValue}
              setChassis={setChassis}
              setModel={setModel}
            />
          ) : (
            <div className="w-full md:w-6/12 ">
              <select
                className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                name="brand"
                value={optionValue}
                disabled={true}
                aria-label={localizedData.optionsFields.option}
                onChange={(e) => setOptionValue(e.target.value)}
              >
                <option value="">{localizedData.optionsFields.option}</option>
              </select>
            </div>
          )}
        </div>

        {optionValue ? (
          <VehicleSize localizedData={localizedData} sizeValue={sizeValue} setSizeValue={handleSetSize} Chassis={Chassis} Model={Model} optionValue={optionValue} />
        ) : (
          <div className="w-full md:w-6/12 md:pr-4 ">
            <select
              className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
              name="brand"
              value={sizeValue}
              disabled={true}
              aria-label={localizedData.optionsFields.size}
              onChange={(e) => handleSetSize(e.target.value)}
            >
              <option value="">{localizedData.optionsFields.size}</option>
            </select>
          </div>
        )}
      </div>
      {showSubmitButton &&
        (sizeValue ? (
          <button
            onClick={handleSubmitForm}
            disabled={submitting}
            className={`${
              submitting ? 'loading' : ''
            } has-cherry-to-crimson-horizontal-gradient-background icon-wheel-for-btn ml-auto flex cursor-pointer items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10 `}
          >
            {submitting ? <span>{localizedData.optionsFields.searching_for_tires}...</span> : <span>{localizedData.optionsFields.find_tires}</span>}
          </button>
        ) : (
          <button
            disabled={submitting}
            className="bg-medium-gray icon-wheel-for-btn ml-auto flex cursor-not-allowed items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10"
          >
            <span>{localizedData.optionsFields.find_tires}</span>
          </button>
        ))}
    </form>
  );
}
