import React from 'react';

import ChangeLocationPortalText from './ChangeLocationPortalText';
import LocationDetailsPortalText from './LocationDetailsPortalText';

function CartLocationDetails({ cookies, localizedData, setShowChangeLocationModal, setShowLocationDetailsModal }) {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return null;
  };

  return (
    <>
      {cookies.stm_preferred_location ? (
        <div className="mb-8 rounded p-4 shadow">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <h2 className="font-primary text-primary mb-4 font-bold">{localizedData.optionsFields.cart_translation_strings.southern_tire_mart_location}</h2>
            </div>
          </div>
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4 md:w-2/3">
              <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4 md:w-1/2">
                  <address className="mb-2 block not-italic">{cookies.stm_preferred_location.address_line_1}</address>
                  <div className="mb-2">
                    {cookies.stm_preferred_location.address_city}, {cookies.stm_preferred_location.address_region} {cookies.stm_preferred_location.address_postal_code}
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2">
                  {cookies.stm_preferred_location.main_phone && (
                    <div className="mb-2">
                      <a href={`tel:${formatPhoneNumber(cookies.stm_preferred_location.main_phone)}`}>{formatPhoneNumber(cookies.stm_preferred_location.main_phone)}</a>
                    </div>
                  )}
                  {cookies.stm_preferred_location.emails && (
                    <div className="mb-2">
                      <a href={`mailto:${cookies.stm_preferred_location.emails}`}>{cookies.stm_preferred_location.emails}</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full px-4 md:w-1/3">
              <LocationDetailsPortalText setShowLocationDetailsModal={setShowLocationDetailsModal} localizedData={localizedData} />
              <ChangeLocationPortalText setShowChangeLocationModal={setShowChangeLocationModal} localizedData={localizedData} />
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-8 flex items-center justify-center rounded p-4 shadow">
          <div className="font-primary text-2xl font-bold">{localizedData.optionsFields.cart_translation_strings.no_preferred_location_set}</div>
        </div>
      )}
    </>
  );
}

export default CartLocationDetails;
