import $ from 'jquery';

export default function cart() {
  const cart = $('.cart .product-quantity input');
  const cartButton = $('.cart .button');

  cart.on('change', () => {
    cartButton.trigger('click');
  });
}
