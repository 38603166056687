import $ from 'jquery';

export default function aria() {
  const wpButtons = document.querySelectorAll('.wp-block-button');

  wpButtons.forEach(function (button) {
    const buttonAriaDescription = button.getAttribute('aria-description');
    if (button.getAttribute('aria-description')) {
      button
        .querySelector('a')
        .setAttribute('aria-description', buttonAriaDescription);
    }
  });

  function addKeyboardInterface(els) {
    if (els) {
      els.forEach(function (el) {
        el.setAttribute('tabindex', 0);

        el.addEventListener('keydown', function (e) {
          if (e.keyCode === 13) {
            e.preventDefault;
            const click = new Event('click');
            el.dispatchEvent(click);
          }
        });
      });
    }
  }

  const filterDropdowns = document.querySelectorAll(
    '#filtersForm .bapf_colaps_togl'
  );
  addKeyboardInterface(filterDropdowns);
  const infoBoxTooltips = document.querySelectorAll('.filter-info-indicator');
  addKeyboardInterface(infoBoxTooltips);
  const closeTooltipIcons = document.querySelectorAll('.filter-info-close');
  addKeyboardInterface(closeTooltipIcons);
}
