import React from 'react';

const DownloadPDF = ({ localizedData, offset = false }) => {
  return (
    <div>
      {localizedData.optionsFields.download_locations_pdf && (
        <a
          href={localizedData.optionsFields.download_locations_pdf.url}
          target={localizedData.optionsFields.download_locations_pdf.target}
          className="text-xxs text-secondary relative mb-1 flex items-center font-normal leading-normal underline hover:no-underline md:text-sm"
        >
          <img
            className={`inline-block ${offset ? 'md:absolute top-0 left-0 md:-ml-6' : ''} mt-1 h-2 w-2 md:h-4 md:w-4 mr-1 md:mr-2`}
            src={`${localizedData.themeUri}/dist/front/images/download-red.svg`}
            alt=""
          />
          {localizedData.optionsFields.download_locations_pdf.title}
        </a>
      )}
    </div>
  );
};

export default DownloadPDF;
