class Dropdowns {
    constructor() {
        this.events();
    }

    events() {
        const hash = window.location.hash.substring(1);
        
        if (hash) {
            const dropdown = $('#' + hash);
            
            $(window).on('load', () => {
                dropdown.trigger('click');
            })
        }
    }
}

export default Dropdowns;