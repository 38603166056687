import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function TireWidth({ localizedData, widthValue, setwidthValue, type }) {
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchSizes = async (controller) => {
    setLoading(true);
    const { data } = await axios.get('/wp-json/stm/v1/lookup/tire/width', {
      signal: controller.signal,
      params: {
        type,
      },
    });
    setSizes(data);
    setLoading(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchSizes(controller);
    return () => {
      controller.abort();
    };
  }, [type]);

  return (
    <div className="w-full ">
      <label htmlFor="tireWidth" className="sr-only">
        {localizedData.optionsFields.tire_width}
      </label>
      <select
        className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
        name="Tire Width"
        id="tireWidth"
        value={widthValue}
        disabled={loading}
        aria-label={localizedData.optionsFields.tire_width}
        onChange={(e) => setwidthValue(e.target.value)}
      >
        {loading ? <option value="">{localizedData.optionsFields.loading}</option> : <option value="">{localizedData.optionsFields.tire_width}</option>}
        {sizes && sizes.map((size) => {
          return (
            <option value={size} key={size}>
              {size}
            </option>
          );
        })}
      </select>
    </div>
  );
}
