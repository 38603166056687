import React, { useState } from 'react';
import FindALocationWithPageRefresh from '../LocationPicker/FindALocationWithPageRefresh';

export default function CommercialAccountForm({
    cookies,
    setShowChangeLocationModal,
    setRedirectAfterLocationSelected,
    localizedData
}) {
    const [selected, setSelected] = useState('more');
    const [showHighIncomeForm, setShowHighIncomeForm] = useState(false);
    const [showMainForm, setShowMainForm] = useState(false);
    
    const handleSetSelected = (value) => {
        if (value) {
            setSelected(value);
        }
    }

    const handleSetShowHighIncomeForm = (value) => {
        const body = document.body;
        
        if (value === true) {
            body.classList.add('show-high-income-form');
        } else {
            body.classList.remove('show-high-income-form');
        }
        setShowHighIncomeForm(value);
    }

    const handleSetShowMainForm = (value) => {
        const body = document.body;
        
        if (value === true) {
            body.classList.add('show-main-form');
        } else {
            body.classList.remove('show-main-form');
        }
        setShowMainForm(value);
    }

    const goBack = () => {
        const body = document.body;
        body.classList.remove('show-high-income-form');
        body.classList.remove('show-main-form');
        setShowHighIncomeForm(false);
        setShowMainForm(false);
    }
    
    return (
        <>
            {showHighIncomeForm || showMainForm ? (
                <>
                    <div className='mb-6 text-primary font-primary font-bold hover:underline cursor-pointer' onClick={() => goBack()}>&lt; {window.localizedData.optionsFields.commercial_account_header_section.back}</div>

                    {!cookies.stm_preferred_location && (
                        <>
                            <h2 className="text-2xl lg:text-4xl font-bold mb-4 lg:pr-4">{ window.localizedData.optionsFields.cart_translation_strings.please_choose_location }</h2>
                            <FindALocationWithPageRefresh
                                setShowChangeLocationModal={setShowChangeLocationModal}
                                setRedirectAfterLocationSelected={setRedirectAfterLocationSelected}
                                localizedData={localizedData}
                            />
                        </>
                    )}
                </>
            ) : ''}

            {!showHighIncomeForm && !showMainForm && (
                <>
                    <div className='commercial-account__header-content'>
                        <h2 className='text-primary font-primary font-bold text-2xl md:text-4xl mb-4'>{window.localizedData.optionsFields.commercial_account_header_section.title}</h2>
                        <p className='text-dark-gray font-secondary text-lg md:text-2xl mb-4'>{window.localizedData.optionsFields.commercial_account_header_section.description}</p>
                        <div onClick={() => handleSetShowMainForm(true)} className='btn-stm btn-wheel relative hover:no-underline from-primary-btn-light to-primary-btn-dark font-secondary inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-6 py-4 font-bold uppercase text-white mb-8'>
                            <div className="flex items-center justify-between">
                                <div className='mr-2'>{window.localizedData.optionsFields.commercial_account_header_section.button.title}</div>
                                <img
                                    className={`h-6 w-6`}
                                    src={`${window.localizedData.themeUri}/dist/images/wheel.svg`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>

                    <div className='commercial-account__wrapper rounded-2xl overflow-hidden border-solid border-2 border-primary mb-4'>
                        <div className='commercial-account__content bg-primary p-4 md:p-12'>
                            <h2 className='text-white font-primary font-bold text-2xl md:text-4xl mb-4'>{window.localizedData.optionsFields.commercial_account_income_section.title}</h2>
                            <p className='text-white font-secondary text-lg md:text-2xl mb-4'>{window.localizedData.optionsFields.commercial_account_income_section.content}</p>
                        </div>
                        <div className='commercial-account__options flex flex-wrap'>
                            <div onClick={() => handleSetSelected('more')} className={`commercial-account__option ${selected === 'more' ? 'selected' : ''} w-full md:w-1/2 p-6 md:p-12 transition-colors duration-300 hover:bg-light-gray cursor-pointer`}>
                                <div className='flex flex-wrap items-center'>
                                    <div className='commercial-account__option-radio w-8 h-8 mr-2 border-2 border-solid border-primary rounded-full'></div>
                                    <div className='commercial-account__option-label text-lg font-bold text-dark-gray'>{window.localizedData.optionsFields.commercial_account_income_section.option_one}</div>
                                </div>
                            </div>
                            <div onClick={() => handleSetSelected('less')} className={`commercial-account__option ${selected === 'less' ? 'selected' : ''} w-full md:w-1/2 p-6 md:p-12 transition-colors duration-300 hover:bg-light-gray cursor-pointer border-solid md:border-l-2 border-primary`}>
                                <div className='flex flex-wrap items-center'>
                                    <div className='commercial-account__option-radio w-8 h-8 mr-2 border-2 border-solid border-primary rounded-full'></div>
                                    <div className='commercial-account__option-label text-lg font-bold text-dark-gray'>{window.localizedData.optionsFields.commercial_account_income_section.option_two}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        {selected === 'less' ? (
                            <a href={window.localizedData.optionsFields.commercial_account_income_section.button.url} target={window.localizedData.optionsFields.commercial_account_income_section.button.target} className='bg-primary hover:bg-retail-cta-background-light px-12 py-4 text-white uppercase rounded-xl inline-block hover:no-underline'>{window.localizedData.optionsFields.commercial_account_income_section.button.title}</a>
                        ) : (
                            <div onClick={() => handleSetShowHighIncomeForm(true)} className='bg-primary hover:bg-retail-cta-background-light px-12 py-4 text-white uppercase rounded-xl inline-block hover:no-underline cursor-pointer'>{window.localizedData.optionsFields.commercial_account_income_section.button.title}</div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
