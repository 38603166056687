import React from 'react'

function PhoneCTAPortal({
    localizedData,
    loadingPreferredLocation,
    cookies,
    setShowChangeLocationModal,
    showChangeLocationModal,
    handleSetShowChangeLocationModal
}) {

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return null;
    };
    
    return (
        <>
          {cookies.stm_preferred_location ? (
            <a href={`tel:${formatPhoneNumber(cookies.stm_preferred_location.main_phone)}`} className="btn-stm btn-wheel hover:no-underline from-primary-btn-light to-primary-btn-dark font-secondary relative inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-4 py-2 font-bold uppercase text-white">
              <div className="flex items-center justify-between">
                <div className="mr-2">
                    {formatPhoneNumber(
                        cookies.stm_preferred_location.main_phone
                    )}
                </div>
                <img
                  className={`${loadingPreferredLocation ? 'animate-spin' : ''} h-6 w-6`}
                  src={`${localizedData.themeUri}/dist/images/wheel.svg`}
                  alt=""
                />
              </div>
            </a>
          ) : (
            <a
              onClick={() => setShowChangeLocationModal(true)}
              onKeyPress={() => handleSetShowChangeLocationModal(event, true)}
              href="#"
              aria-haspopup="true"
              tabIndex={0}
              className="change-location-trigger text-secondary underline hover:no-underline"
            >
              {cookies.stm_preferred_location 
                ? localizedData.optionsFields.header_labels.your_location_change
                : localizedData.optionsFields.header_labels.find_a_location_modal_header}
            </a>
          )}
        </>
    );
}

export default PhoneCTAPortal