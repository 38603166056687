import React from 'react';

function MakeThisMyStorePortalButton({
  handleSetPreferredLocation,
  localizedData,
  loadingPreferredLocation,
  cookies
}) {
  const buttonRoot = document.getElementById(
    'MakeThisMyStorePortalButtonDOMNode'
  );

  return (
    <>
      {cookies.stm_preferred_location?.entity_id !== buttonRoot.dataset.entityId ? (
        <div className="btn-stm btn-wheel hover:no-underline from-primary-btn-light to-primary-btn-dark font-secondary relative inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-4 py-2 font-bold uppercase text-white">
          <div className="flex items-center justify-between">
            <div className="mr-2">
              {localizedData.optionsFields.header_labels.make_this_my_store}
            </div>
            <img
              className={`${loadingPreferredLocation ? 'animate-spin' : ''} h-6 w-6`}
              src={`${localizedData.themeUri}/dist/images/wheel.svg`}
              alt=""
            />
          </div>
          <div
            className="absolute inset-0 h-full w-full cursor-pointer"
            onClick={(e) => handleSetPreferredLocation(e)}
            data-entity-id={buttonRoot.dataset.entityId}
          ></div>
        </div>
      ) : ''}
    </>
  );
}

export default MakeThisMyStorePortalButton;
