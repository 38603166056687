import React from 'react'

function ChangeLocationPortalButton({
    setShowChangeLocationModal,
    localizedData
}) {
  return (
    <div 
    onClick={() => setShowChangeLocationModal(true)}
    aria-haspopup="true"
    className="btn-stm btn-wheel relative hover:no-underline from-primary-btn-light to-primary-btn-dark font-secondary inline-block cursor-pointer rounded-r-full bg-gradient-to-r px-4 py-2 font-bold uppercase text-white" 
    >
        <div className="flex items-center justify-between">
            <div className="mr-2">{localizedData.optionsFields.header_labels.my_location_change_location}</div>
            <img 
            className="h-6 w-6" 
            src={`${localizedData.themeUri}/dist/images/wheel.svg`}
            alt="" 
            />
        </div>
    </div>
  )
}

export default ChangeLocationPortalButton