import axios from 'axios';
import React, { useEffect, useState } from 'react';

import VehicleApplication from './VehicleApplication';
import VehiclePosition from './VehiclePosition';
import VehicleSizeCommercial from './VehicleSizeCommercial';

export default function VehicleCommercial({ 
  localizedData, 
  loading, 
  setLoading, 
  submitting, 
  setsubmitting, 
  onSelectSize, 
  showSubmitButton = true,
  isModelLookup
}) {
  const [applicationsValue, setapplicationsValue] = useState('');
  const [positionValue, setpositionValue] = useState('');
  const [sizeValue, setsizeValue] = useState('');

  const handleSubmitForm = async () => {
    setsubmitting(true);
    const { data: termOptions } = await axios.get(`/wp-json/stm/v1/lookup/vehicle/term-options?tireSize=${sizeValue}`);
    window.location.href = `${termOptions}?tire_size=${sizeValue}&type=Commercial`;
  };

  const handleSetSize = (size: string) => {
    setsizeValue(size);
    if (onSelectSize && typeof onSelectSize === 'function') {
      onSelectSize(size);
    }
  };

  useEffect(() => {
    setpositionValue('');
    setsizeValue('');
  }, [applicationsValue]);

  useEffect(() => {
    setsizeValue('');
  }, [positionValue]);

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <div className="vehicle-commercial">
        <div className="flex w-full flex-wrap">
          <VehicleApplication localizedData={localizedData} applicationsValue={applicationsValue} setapplicationsValue={setapplicationsValue} />
          {applicationsValue ? (
            <VehiclePosition localizedData={localizedData} applicationsValue={applicationsValue} positionValue={positionValue} setpositionValue={setpositionValue} />
          ) : (
            <div className="w-full ">
              <select
                className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                name="position"
                value={positionValue}
                disabled={true}
                aria-label={localizedData.optionsFields.position}
                onChange={(e) => setpositionValue(e.target.value)}
              >
                <option value="">{localizedData.optionsFields.position}</option>
              </select>
            </div>
          )}

          {positionValue ? (
            <VehicleSizeCommercial localizedData={localizedData} sizeValue={sizeValue} setsizeValue={handleSetSize} applicationsValue={applicationsValue} positionValue={positionValue} />
          ) : (
            <div className="w-full">
              <select
                className="text-small text-small border-dark-gray mb-4 w-full border px-2 py-1 text-black"
                name="size"
                value={sizeValue}
                disabled={true}
                aria-label={localizedData.optionsFields.size}
                onChange={(e) => handleSetSize(e.target.value)}
              >
                <option value="">{localizedData.optionsFields.size}</option>
              </select>
            </div>
          )}
        </div>
      </div>

      {showSubmitButton &&
        (sizeValue ? (
          <button
            onClick={handleSubmitForm}
            disabled={submitting}
            className={`${
              submitting ? 'loading' : ''
            } has-cherry-to-crimson-horizontal-gradient-background icon-wheel-for-btn ml-auto flex cursor-pointer items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10 `}
          >
            {submitting ? <span>{localizedData.optionsFields.searching_for_tires}...</span> : <span>{localizedData.optionsFields.find_tires}</span>}
          </button>
        ) : (
          <button
            disabled={submitting}
            className="bg-medium-gray icon-wheel-for-btn ml-auto flex cursor-not-allowed items-center rounded-r-full px-4 py-2 font-bold uppercase text-white md:absolute md:right-0 md:bottom-0 md:m-10"
          >
            <span>{localizedData.optionsFields.find_tires}</span>
          </button>
        ))}
    </form>
  );
}
