class ButtonIcons {
    constructor() {
        this.events();
    }

    events() {
        $('.wp-block-button.has-wheel-icon').on('click', this.triggerWheelSpin);
        $('.wp-block-button.has-semi-truck-icon').on('click', this.triggerWheelSpin);
        $('.wp-block-button.has-car-icon').on('click', this.triggerWheelSpin);
        $(document).on('click', 'a', this.triggerWheelSpin);
        $('button').on('click', this.triggerWheelSpin);
        $('input[type="submit"]').on('click', this.triggerWheelSpin);
        $('input[type="button"]').on('click', this.triggerWheelSpin);
    }

    triggerWheelSpin() {
        if ($(this).hasClass('wp-block-button')) {
            $(this).addClass('is-spinning');

            setTimeout(() => {
                $(this).removeClass('is-spinning');
            }, 5000);
            
            return;
        }
        
        if ($(this).find('img').length > 0 && $(this).find('img').attr('src').indexOf("wheel.svg") >= 0) {
            $(this).find('img').addClass('animate-spin');

            setTimeout(() => {
                $(this).find('img').removeClass('animate-spin');
            }, 5000);

            return;
        }

        if ($(this).is("input") && !$(this).hasClass('application_button')) {
            $(this).addClass('spin-wheel');

            setTimeout(() => {
                $(this).removeClass('spin-wheel');
            }, 5000);
        }
    }
}

export default ButtonIcons;