import React from 'react'

function CheckoutLocationDetails({
    setShowChangeLocationModal,
    localizedData,
    cookies,
}) {

    const formatPhoneNumber = (phoneNumberString) => {
        const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return null;
      };
    
  return (
    <div>
        <div className="flex items-center justify-between">
            <h2 className="font-primary font-bold text-2xl text-primary mb-4">{localizedData.optionsFields.cart_translation_strings.southern_tire_mart_location}</h2>
            <img tabIndex={0} aria-haspopup="true" onClick={() => setShowChangeLocationModal(true)} className="w-10 h-10 cursor-pointer" src={`${localizedData.themeUri}/dist/images/edit.svg`} alt="Edit" />
        </div>
        <div className="flex flex-wrap items-center">
            <div className="w-full md:w-1/2 lg:w-1/3">
                <address className="not-italic">
                <div>{cookies.stm_preferred_location.address_line_1}</div>
                <div>
                    {cookies.stm_preferred_location.address_city},{' '}
                    {cookies.stm_preferred_location.address_region}{' '}
                    {cookies.stm_preferred_location.address_postal_code}
                </div>
                </address>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3">
                <div>
                    {cookies.stm_preferred_location.main_phone && (
                        <div>
                        {formatPhoneNumber(
                            cookies.stm_preferred_location.main_phone
                        )}
                        </div>
                    )}
                    {cookies.stm_preferred_location.emails && (
                        <a
                        className="hover:underline"
                        href={`mailto:${cookies.stm_preferred_location.emails}`}
                        >
                        {cookies.stm_preferred_location.emails}
                        </a>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default CheckoutLocationDetails